@font-face {
  font-family: "AppleSDGothicNeo";
  font-style: normal;
  font-weight: 400;
  src: url("../public/font/AppleSDGothicNeoUL.ttf");
}

@font-face {
  font-family: "AppleSDGothicNeo";
  font-style: normal;
  font-weight: 600;
  src: url("../public/font/AppleSDGothicNeoM.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}
