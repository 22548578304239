body {
  width: screen;
  height: screen;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

::placeholder {
  color: #c4c4c4;
}

/* date input 커스텀을 위한 속성 */
input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #c4c4c4;
}

input[type="date"] {
  color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
  color: #c4c4c4;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "";
}

input[type="date"]:not(:focus):invalid {
  &::-webkit-datetime-edit-text,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field,
  &::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
  }
}
